import moment from "moment";
import _ from "lodash";

export const convertSlashDateToMoment = (slashDate) => {
  // console.log("convertSlashDateToMoment", slashDate);
  const dateArr = slashDate
    .split("/")
    .reverse()
    .map((ele) => parseInt(ele));
  return moment().set({
    year: dateArr[0],
    month: dateArr[1] - 1,
    date: dateArr[2],
  });
};

export const formatMomentDateByLang = (momentObj, lang = "en") => {
  // console.log("momentObj", momentObj);
  if (lang === "en") momentObj.locale("en");
  if (lang === "tc") momentObj.locale("zh-tw");
  if (lang === "sc") momentObj.locale("zh-cn");
  return momentObj.format(lang === "en" ? "D MMMM YYYY" : "LL");
};

export const addMomentToPost = (post, path) => {
  const postClone = _.cloneDeep(post);
  const slashDate = _.get(postClone, path);
  const momentObj = convertSlashDateToMoment(slashDate);
  const postWithMoment = _.set(postClone, path, momentObj);
  return postWithMoment;
};

export const mapMomentToPosts = (posts, path) => {
  return posts.map((post) => {
    const updatedPost = addMomentToPost(post, path);
    return updatedPost;
  });
};